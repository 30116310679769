<template>
  <div class="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  created () {
    // const _url = process.env.VUE_APP_URL
    // alert(_url)
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less">
/*less使用
1:安装  npm i less less-loader  -D
2:使用  style lang="less"
 */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  font-size: 14px;
}
.app,
body,
html {
  height: 100%;
  ::-webkit-scrollbar {
    width: 10px;
    height: 16px;
    border-radius: 5px;
    background-color: #f5f5f5;
  }
  // /*定义滚动条轨道 内阴影+圆角*/
  // ::-webkit-scrollbar-track {
  //   // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  //   border-radius: 10px;
  //   background-color: #f5f5f5;
  // }
  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(223, 223, 223, 0.1);
    background-color: #e2e2e2;
  }
}
.spinner {
  display: none !important;
}
</style>
