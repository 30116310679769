import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login/login.vue'
import layout from '@/views/layout/layout.vue'
import { saveLocal, getLocal } from '@/utils/local'

// import Nprogress from 'nprogress'
// import 'nprogress/nprogress.css'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: login,
    meta: {
      title: '安保客户维护系统',
      roles: ['']
    }
  },
  {
    path: '/layout',
    component: layout,
    meta: {
      title: '安保客户维护系统',
      roles: ['']
    },
    children: [
      {
        path: '/layout/customer',
        component: () => import('@/views/layout/customer/customer.vue'),
        meta: {
          title: '客户管理',
          icon: 'el-icon-files',
          roles: []
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // to:去哪里  要去的路由的信息  要去的路由的 $route
  // from:从哪来 从哪个路由过来的路由信息  $route
  // next是否通过  正常通过  next()  不允许 next(path)
  // Nprogress.start()
  // 当前要to的路由的路由元中是否包含当前登录人的角色 如果包含  next() 不包含 提示 清除token next(/login)
  // if (to.meta.roles.includes(store.state.role)) {
  // if (isNot(localStorage.getItem('root'), to.meta.roles)) {
  // console.log('前置路由去==>', to.path.indexOf('layout'))
  if (to.path.indexOf('/layout/') !== -1) {
    var tabsData = []
    // console.log('前置路由去==>', to.path)
    // console.log("getLocal('tabsData')==>", getLocal('tabsData'))
    if (getLocal('tabsData') === null) {
      tabsData.push({
        title: to.meta.title,
        name: to.path
      })
      saveLocal('tabsData', JSON.stringify(tabsData))
    } else {
      tabsData = JSON.parse(getLocal('tabsData'))
      // console.log(tabsData)
      const datas = tabsData.some(item => {
        return item.name === to.path
      })
      console.log(datas)
      if (!datas) {
        tabsData.push({
          title: to.meta.title,
          name: to.path
        })
        console.log('1111', tabsData)
        saveLocal('tabsData', JSON.stringify(tabsData))
      }
    }
    // console.log('保存的标签页', JSON.parse(getLocal('tabsData')))
  }

  next()
  // } else {
  //   // 提示一下
  //   Message.error('您无权访问该页面')
  //   // 清除token
  //   removeLocal('token')
  //   // 跳转至登录页
  //   next('/login')
  // }
})
// 后置导航守卫
router.afterEach((to, from) => {
  // Nprogress.done()
  // to:去哪里  要去的路由的信息  要去的路由的 $route
  // from:从哪来 从哪个路由过来的路由信息  $route
  // window.console.log('to:', to)
  // window.console.log('from:', from)
  // 在跳转到数据概览后修改title
  // document.getElementById('title').innerText = to.meta.title
  document.title = to.meta.title
  // if (to.path == '/layout/chart') {
  //   document.getElementById('title').innerText = '数据概览'
  // } else if (to.path == '/layout/subject') {
  //   document.getElementById('title').innerText = '学科列表'
  // }
})

export default router
