<template>
  <div class="layout">
    <el-container class="content">
      <el-header class="top">
        <!-- <img
          src="../../assets/login/圣集logo@2x.png"
          alt=""
          :style="{ width: '135px' }"
        /> -->
        <span class="title">安保客户维护系统</span>
        <div class="userImg">
          <!-- <img src="../../assets/layout/huge.jpg" /> -->
          <!-- <span class="wec">系统管理员</span> -->
          <el-dropdown>
            <span class="el-dropdown-link name">
              <span class="wec">系统管理员</span>
              {{ realName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="exit()">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container class="banner" width="260px">
        <el-aside width="200px" class="left">
          <el-menu
            :default-active="$route.path"
            router
            class="left-demo"
            @select="handleSelect"
          >
            <template
              v-for="(item, index) in $router.options.routes[2].children"
            >
              <el-submenu
                :key="index"
                :index="item.path"
                v-if="item.children !== undefined"
              >
                <template slot="title">
                  <i :class="item.meta.icon"></i>
                  <span slot="title" class="title">{{ item.meta.title }}</span>
                </template>
                <template v-for="(v, indexs) in item.children">
                  <el-menu-item
                    :key="indexs"
                    :index="v.path"
                    v-if="item.children !== undefined"
                  >
                    <i :class="v.meta.icon"></i>
                    <span slot="title" class="title">{{ v.meta.title }}</span>
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item :key="index" :index="item.path">
                <i :class="item.meta.icon"></i>
                <span slot="title" class="title">{{ item.meta.title }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-aside>
        <el-main class="center">
          <el-tabs
            :value="$route.path"
            type="card"
            :closable="closables"
            @tab-remove="removeTab"
            @tab-click="handleClick"
          >
            <el-tab-pane
              v-for="item in editableTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
              <div class="cardContent">
                <router-view></router-view>
                <!-- <div :style="{ background: '#ffffff', height: '100%' }">
                </div> -->
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { getLocal, saveLocal, removeLocal } from '@/utils/local'
export default {
  computed: {
    closables: function () {
      if (this.editableTabs.length === 1) {
        return false
      } else {
        return true
      }
    }
  },
  data () {
    return {
      realName: '',
      superAdmin: '',
      str: '',
      permissionsList: [],
      userInfo: '',
      // closable: true,
      local: localStorage.getItem('root'),
      dataPath: '',

      editableTabsValue: '/layout/customer',
      editableTabs: [
        // {
        //   title: '首页',
        //   name: '/layout/pageHome',
        //   content: 'Tab 1 content'
        // },
        // {
        //   title: '设备管理',
        //   name: '/layout/equiPment',
        //   content: 'Tab 2 content'
        // }
      ],
      tabIndex: 2
    }
  },
  created () {
    if (localStorage.getItem('token') === null) {
      this.$message.error('请先登录!')
      this.$router.push('/login')
    }
    this.editableTabs = JSON.parse(getLocal('tabsData'))
  },
  methods: {
    isNot (arr1, arr2) {
      // console.log('arr1', arr1)
      if (arr1 === '' || arr1 === null) {
        return true
      }
      for (let i = 0; i < arr2.length; i++) {
        if (arr1.includes(arr2[i])) {
          // console.log('包含的数据', arr2[i])
          return true
        }
      }
      return false
    },
    // 导航栏选中后的回调(虽然在路由守卫里面用localStorage保存了,但那边是异步的,这边做个掩体)
    handleSelect (key) {
      // console.log(key)
      this.editableTabsValue = key
      this.editableTabs = JSON.parse(getLocal('tabsData'))
      console.log('this.editableTabs==>', this.editableTabs)
      var data = ''
      // 遍历数组得到标签名
      this.$router.options.routes[2].children.map(item => {
        if (item.path === key) {
          // console.log(item.meta.title)
          data = item.meta.title
        }
        return item
      })
      // 判断数组是否添加这个标签
      const datas = this.editableTabs.some(item => {
        return item.name === key
      })
      if (!datas) {
        this.editableTabs.push({
          title: data,
          name: key
        })
        // saveLocal('editableTabs', this.editableTabs)
      }
    },
    // 标签页删除后的回调
    removeTab (targetName) {
      const tabs = this.editableTabs
      let activeName = this.editableTabsValue
      // console.log('删除的==>', targetName)
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.name
            }
          }
        })
      }
      this.editableTabsValue = activeName
      this.$router.push(activeName)
      if (this.editableTabs.length === 1) {
      } else {
        this.editableTabs = tabs.filter(tab => tab.name !== targetName)
      }
      // console.log('const tabs==>', tabs)
      saveLocal('tabsData', JSON.stringify(this.editableTabs))
    },
    // 标签页选中后的回调
    handleClick (tab, event) {
      // console.log('=====>', this.$store.state.tabsTitle)
      // console.log('标签页==>', tab.name, event)
      // console.log('标签页==>', this.editableTabsValue)
      this.$router.push(tab.name)
    },
    // 退出
    exit () {
      this.$router.push('/login')
      removeLocal('tabsData')
      removeLocal('token')
    }
  }
}
</script>

<style lang="less">
.layout {
  height: 100%;
  .el-menu-item.is-active {
    background-color: #317cee;
    color: #fff;
    border-radius: 10px;
  }
  .el-menu-item.is-active:hover {
    background-color: #317cee;
    color: #fff;
    border-radius: 10px;
  }
  .el-menu-item {
    margin: 0 20px;
    transition: color 0.3s;
  }
  .el-menu-item,
  .el-submenu__title {
    height: 50px;
    line-height: 50px;
    position: relative;
    // -webkit-box-sizing: border-box;
    white-space: nowrap;
    list-style: none;
  }
  .el-menu-item:hover {
    background-color: #ecf5ff;
    border-radius: 10px;
  }
  .el-tabs.el-tabs--card.el-tabs--top {
    background-color: #fff;
    height: 100%;
    // height: 89vh;
  }
  .el-tabs__item {
    padding: 0 20px;
    height: 35px;
    box-sizing: border-box;
    line-height: 35px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #303133;
    position: relative;
  }
  .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0;
  }
  .el-main {
    display: block;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    box-sizing: border-box;
    padding: 0px;
  }
  .content {
    height: 100%;
    .cardContent {
      background-color: #f8faff;
      padding: 20px 0px 0px 20px;
      height: 89vh;
    }
    .top {
      display: flex;
      align-items: center;
      height: 30px;
      width: 100%;
      position: relative;
      .name {
        font-size: 14px;
      }
      .icon {
        font-size: 30px;
        color: skyblue;
        line-height: 30px;
        margin-right: 15px;
      }
      .title {
        color: #6363b8;
        font-weight: 700;
        font-size: 20px;
      }
      .userImg {
        position: absolute;
        right: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .wec {
          margin-right: 0px;
        }
      }
    }
    .banner {
      .left-demo {
        height: 100%;
        width: 200px;
        background-color: #fff;
        border-right: none;
      }
      .center {
        background-color: #f8faff;
      }
    }
  }
}
</style>
