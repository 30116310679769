import axios from 'axios'
import { Message } from 'element-ui'
import { getLocal, removeLocal } from '@/utils/local.js'
import router from '@/router/index.js'

const _fetch = axios.create({
  baseURL: process.env.VUE_APP_URL,
  withCredentials: true
})
// 添加请求拦截器
_fetch.interceptors.request.use(
  function (config) {
    config.headers.token = getLocal('token')
    // window.console.log('请求拦截', config)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
// 添加响应拦截器
// else if (response.data === '') {
//   console.log(111111)
//   return response
// }
_fetch.interceptors.response.use(
  function (response) {
    if (response.data.code === 0) {
      return response
    } else if (response.data.code === 401 || response.data.code === 10000002) {
      removeLocal('token')
      removeLocal('tabsData')
      Message({
        message: response.data.msg,
        type: 'error'
      })
      router.push('/login')
      // https://iotest.saintscare.cn/customerCenter/admin/exportCustomerInfo?phoneNum=&customerName=&status=
      return Promise.reject(response.data.msg)
    } else if (response.data.type === 'application/octet-stream') {
      return response
    } else {
      console.log('response', response)
      Message({
        message: response.data.msg + '!',
        type: 'error'
      })

      return Promise.reject(response.data.msg)
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)
export default _fetch
