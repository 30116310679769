import _fetch from './request'
// 登录
function login (data) {
  return _fetch({
    url: '/admin/login',
    method: 'post',
    data
  })
}
// 获取客户列表数据
function queryCustomerList (data) {
  return _fetch({
    url: '/admin/queryCustomerList',
    method: 'post',
    data
  })
}
// 处理客户申请
function handleDataReq (openId) {
  return _fetch({
    url: '/admin/handleDataReq/' + openId,
    method: 'post'
  })
}
// 客户数据导出
function exportCustomerInfo (params) {
  return _fetch({
    url: '/admin/exportCustomerInfo',
    responseType: 'blob',
    method: 'get',
    header: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params
  })
}
// // 导出客户数据
// function exportData (data) {
//   return _fetch({
//     url: '/merchantRegion/export',
//     method: 'post',
//     responseType: 'blob',
//     header: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     },
//     data: data
//   })
// }
export {
  login,
  queryCustomerList,
  handleDataReq,
  exportCustomerInfo
  //   exportData
}
