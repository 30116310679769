import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Router from 'vue-router'
import store from './store'

// 导入element-ui
import ElementUI from 'element-ui'
// 导入element-ui的css
import 'element-ui/lib/theme-chalk/index.css'
// 导入axios
import axios from 'axios'
// 导入moment
// import moment from 'moment'

Vue.config.productionTip = false
Vue.use(ElementUI)

const _fetch = axios.create({
  baseURL: process.env.VUE_APP_URL,
  // 跨域带cookie到后端
  withCredentials: true
})

Vue.prototype.$axios = _fetch
// Vue.filter('setData', function (value) {
//   return moment(value).format('YYYY-MM-DD')
// })

// 解决路由重复点击
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
