<template>
  <div class="login">
    <div class="bgImg">
      <div class="top_title">
        <img src="../../assets/login/ABlogo.png" alt="" />
        <div class="lines"></div>
        <div class="texts">安保客户维护系统</div>
      </div>
      <div class="center">
        <div class="title">
          用户登录
        </div>
        <el-form :model="form" :rules="rules" class="form" ref="form">
          <el-form-item prop="userAccount">
            <el-input
              required
              v-model="form.userName"
              class="input"
              placeholder="请输入用户名"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              v-model="form.password"
              class="input"
              placeholder="请输入密码"
              prefix-icon="el-icon-lock"
              show-password
              @keyup.native.enter="toLogin"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="captcha">
            <el-row>
              <el-col :span="16">
                <el-input
                  class="input"
                  v-model="form.captcha"
                  prefix-icon="el-icon-key"
                  placeholder="请输入验证码"
                  @keyup.native.enter="toLogins"
                ></el-input>
              </el-col>
              <el-col :span="8" class="input">
                <img class="login-code" :src="codeUrl" alt="" />
              </el-col>
            </el-row>
          </el-form-item> -->
          <el-form-item>
            <!-- <el-button type="primary" class="btn" @click="toLogin"
              >登录</el-button
            > -->
            <el-button type="primary" class="btn" @click="toLogin"
              >登录</el-button
            >
          </el-form-item>
          <!-- <el-form-item>
            <div class="regist">
              <el-button class="passWord" type="text">忘记密码？</el-button>
              <span class="null">没有账号？</span
              ><el-button type="text" class="goRegist">去注册</el-button>
            </div>
          </el-form-item> -->
        </el-form>

        <!-- <div v-if="isShow">
          <el-form
            :model="addform"
            :rules="Rrules"
            ref="addform"
            class="registForm"
          >
            <el-form-item prop="userAccount">
              <el-input
                v-model="addform.userAccount"
                prefix-icon="el-icon-user"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-row>
                <el-col :span="16">
                  <el-input
                    v-model="addform.code"
                    placeholder="请输入短信验证码"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    type="primary"
                    class="btn"
                    @click="clickSendMsm"
                    :disabled="totalTime != 60"
                  >
                    <span v-if="totalTime === 60"> 获取验证码</span>
                    <span v-if="totalTime != 60">{{ totalTime }}S</span>
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="addform.password"
                show-password
                prefix-icon="el-icon-lock"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>

            <el-form-item prop="confirmPassword">
              <el-input
                v-model="addform.confirmPassword"
                show-password
                prefix-icon="el-icon-lock"
                placeholder="再次确认密码"
              ></el-input>
            </el-form-item>

            <el-form-item prop="checked" label="">
              <el-checkbox v-model="addform.checked"
                >我已阅读并同意<span @click="goService" style="color:#4072fa"
                  >《服务协议》</span
                >和<span @click="goPrivacy" style="color:#4072fa"
                  >《隐私政策》</span
                ></el-checkbox
              >
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit" class="yes"
                >注册</el-button
              >
            </el-form-item>
            <el-form-item>
              <div class="regist">
                <span class="null">已有账号？</span
                ><el-button type="text" class="goRegist" @click="returnLogin"
                  >去登录</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div> -->
        <!-- <div class="reset" v-show="passWord">
          <el-form
            :model="passdwordForm"
            :rules="passdwordRrules"
            ref="passdwordForm"
            class="registForm"
          >
            <el-form-item prop="userAccount">
              <el-input
                v-model="passdwordForm.userAccount"
                prefix-icon="el-icon-user"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-row>
                <el-col :span="16">
                  <el-input
                    v-model="passdwordForm.code"
                    placeholder="请输入短信验证码"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    type="primary"
                    class="btn"
                    @click="cipherSendMsm"
                    :disabled="resetTimes != 60"
                  >
                    <span v-if="resetTimes === 60"> 获取验证码</span>
                    <span v-if="resetTimes != 60">{{ resetTimes }}S</span>
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="passdwordForm.password"
                show-password
                prefix-icon="el-icon-lock"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="reset" class="yes"
                >确认重置</el-button
              >
            </el-form-item>
            <el-form-item>
              <div class="regist">
                <el-button
                  type="text"
                  class="goRegist returnLogin"
                  @click="returnLogin"
                  >返回登录</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div> -->
      </div>
      <div class="record">
        <div>Copyright © 2021-2022 深圳市圣集康护有限公司版权所有</div>
        <span>粤ICP备19138198号</span>
      </div>
    </div>

    <!-- <div class="dialog" v-show="merchantShow">
      <div class="merchantBox">
        <div class="boxTop">
          <h3>请选择您的商户</h3>
          <i
            class="el-icon-close close"
            @click="merchantShow = !merchantShow"
          ></i>
        </div>
        <ul class="boxContent">
          <li
            v-for="item in merchantList"
            :key="item.merchantId"
            @click="listChange(item.merchantId)"
          >
            <p>{{ item.merchantName }}</p>
            <i class="el-icon-arrow-right rightIcon"></i>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
// import { login } from '@/api/crstomer.js'
import { login } from '@/api/customer.js'
export default {
  data () {
    return {
      authInfoList: [],
      isAuth: '',
      infoList: [],
      permissionsParamsList: '',
      baseUrl: process.env.VUE_APP_URL,
      codeUrl: '/captcha.jpg',
      isSuper: '',
      form: {
        userName: '',
        password: ''
        // captcha: '',
        // uuid: "'uuid'"
      },
      rules: {
        userName: [{ required: true, message: '用户名', trigger: 'blur' }],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change'
          },
          { min: 6, max: 12, message: '请输入6-12位密码', trigger: 'change' }
        ]
        // captcha: [
        //   {
        //     required: true,
        //     message: '请输入验证码',
        //     trigger: 'change'
        //   },
        //   { min: 5, max: 5, message: '请输入5位验证码', trigger: 'change' }
        // ]
      }
    }
  },
  methods: {
    toLogin () {
      this.$refs.form.validate(result => {
        window.console.log(result)
        if (result) {
          login(this.form).then(res => {
            localStorage.setItem('token', res.data.data)
            this.$message.success(res.data.msg + '!')
            this.$router.push('/layout/customer')
          })
        } else {
          this.$message.error('验证失败')
        }
      })
    },
    toRegist () {
      console.log(2222)
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  min-width: 1920px;
  min-height: 936px;
  .dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .merchantBox {
      width: 800px;
      height: 500px;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      .boxTop {
        width: 100%;
        height: 130px;
        background: #fff;
        overflow: hidden;
        position: relative;
        h3 {
          color: #303133;
          font-size: 30px;
          font-weight: 400;
          text-align: center;
          // line-height: 130px;
          margin-top: 45px;
        }
        .close {
          font-size: 20px;
          font-weight: 700;
          color: #999999;
          position: absolute;
          top: 50px;
          right: 50px;
        }
      }
      .boxContent {
        // display: flex;
        // align-items: center;
        // flex-direction: column;
        padding: 0 65px;
        height: 330px;
        overflow: auto;
        li {
          width: 680px;
          height: 74px;
          border: 1px solid #999999;
          border-radius: 10px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          padding: 0 45px;
          p {
            font-size: 16px;
            color: #5a6175;
            flex: 1;
          }
          .rightIcon {
            color: #999999;
            font-size: 21px;
            font-weight: 700;
          }
        }
        li:hover {
          background: #4072fa;
          p {
            color: #fff;
          }
          .rightIcon {
            color: #fff;
          }
        }
      }
    }
  }

  .bgImg {
    background: url('../../assets/login/bgImgs.png');
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    position: fixed;
    width: 100%;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    .top_title {
      position: absolute;
      top: 70px;
      left: 72px;
      align-items: center;
      height: 40px;
      width: 582px;
      // background: red;
      display: flex;
      img {
        height: 31px;
        width: 170px;
      }
      .lines {
        width: 4px;
        height: 40px;
        background: #ffffff;
        margin: 0 20px;
      }
      .texts {
        font-size: 36px;
        color: #fff;
        font-weight: 700;
      }
    }
    .center {
      position: absolute;
      right: 260px;
      width: 412px;
      height: 449px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        width: 79%;
        font-size: 32px;
        text-align: left;
        font-weight: 700;
        color: #5a6175;
        letter-spacing: 1px;
        margin-bottom: 30px;
      }
      .form {
        width: 320px;
        .select {
          width: 320px;
        }
        .login-code {
          width: 100%;
          height: 40px;
          margin-left: 4px;
        }
        /deep/ input.el-input__inner {
          background-color: #f5f5f5;
        }
        /deep/.el-form-item__content {
          line-height: 0;
          position: relative;
          font-size: 14px;
        }
        .btn {
          display: block;
          width: 100%;
          margin-top: 20px;
        }
        .btn2 {
          width: 100%;
          opacity: 1;
          background: #ffffff;
          border: 1px solid #498ef6;
          border-radius: 5px;
        }
        .regist {
          margin-top: -20px;
          display: flex;
          height: 50px;
          font-size: 16px;
          align-items: center;
          position: relative;
          .passWord {
            font-size: 16px;
            color: #4276fa;
          }
          .null {
            position: absolute;
            right: 50px;
            line-height: 50px;
            display: inline-block;
            height: 100%;
            color: #999999;
          }
          .goRegist {
            position: absolute;
            right: 0;
            font-size: 16px;
            color: #4276fa;
          }
        }
      }
      .registForm {
        width: 320px;
        /deep/ input.el-input__inner {
          background-color: #f5f5f5;
        }
        /deep/.el-form-item__content {
          line-height: 0;
          position: relative;
          font-size: 14px;
        }
        .regist {
          margin-top: -20px;
          // margin-top: -10px;
          display: flex;
          height: 50px;
          justify-content: center;
          font-size: 16px;
          align-items: center;
          position: relative;
          .null {
            position: absolute;
            right: 50px;
            // margin-top: 15px;
            line-height: 50px;
            display: inline-block;
            height: 100%;
            color: #999999;
          }
          .goRegist {
            position: absolute;
            right: 0;
            font-size: 16px;
            color: #4276fa;
          }
          .returnLogin {
            position: absolute;
            right: 0;
          }
        }
        margin: 0 auto;
        .btnCode {
          text-align: center;
          width: 97%;
          height: 40px;
          margin-left: 4px;
        }
        .yes {
          width: 100%;
        }
        .btn {
          font-size: 12px;
          text-align: center;
          margin-left: 10px;
          width: 100px;
        }
      }
    }
    .record {
      display: flex;
      // width: 540px;
      color: #303133;
      font-weight: 500;
      font-size: 14px;
      position: fixed;
      position: absolute;
      bottom: 50px;
      text-align: center;
      span {
        margin-left: 15px;
      }
    }
  }
}
</style>
